import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import EchartLine from '../dashboard/echarts';
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers, getUserType } from '../utils/common';
import moment from "moment/moment";
import { Tab, Tabs } from 'react-bootstrap';
import TabChart from "../dashboard/TabChart";
const Dashboard = () => {

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="30" color="#333" />,
  });
  const [headersobj] = useState(headers());
  const [userType] = useState(getUserType());
  const [showLoader, setShowloader] = useState(false);

  const [farmerList, setFarmerList] = useState([]);
  const [farmsList, setFarmsList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState('');
  const [dashboardVals, setDashBoardVals] = useState('');
  const [showTabs, setShowTabs] = useState(false);
  const onUserChange = (e) => {
    e.preventDefault();
    if (e.target.value === '') {
      alert('Please select Farm Owner');
      return;
    }
    setFarmsList([]);
    setDevicesList([]);
    setDashBoardVals('');
    setShowTabs(false);
    setSelectedFarmer(e.target.value)
    fetchFarms(e.target.value);
  }
  const onFarmChange = (e) => {
    e.preventDefault();
    setShowTabs(false);
    if (e.target.value === '') {
      alert('Please select Farm');
      return;
    }
    fetchDevices(e.target.value);
  }
  const fetchFarmers = () => {
    setShowloader(true);
    axios.get(env.produrl + '/misc/activeFarmersList/', { headers: headersobj }
    ).then(res => {
      if (!res.data.error) {
        setFarmerList(res.data.data);
        setShowloader(false);
      } else {
        alert('Error in fetching data')
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err);
      setShowloader(false);
    });
  }
  const fetchFarms = (userID) => {
    setShowloader(true);
    let apiLink = `/misc/listoffarmsAdmin/${userID}`;
    if(userType=='CS') {
      apiLink = `/misc/listoffarmsAdmin/7`;
    }
    axios.get(env.produrl + apiLink, { headers: headersobj }
    ).then(res => {
      if (!res.data.error) {
        if (res.data.data.length === 0) {
          alert('No farms found for the selected farmer');
        }
        setFarmsList(res.data.data);
        setShowloader(false);
      } else {
        alert('Error in fetching data')
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err);
      setShowloader(false);
    });
  }
  const fetchDevices = (farmID) => {
    setShowloader(true);
    axios.get(env.produrl + `/misc/listofDevicesByFarm/${farmID}`, { headers: headersobj }
    ).then(res => {
      if (!res.data.error) {
        if (res.data.data.length === 0) {
          alert('No devices found this farm');
        }
        setDevicesList(res.data.data);
        setShowloader(false);
      } else {
        alert('Error in fetching data')
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err);
      setShowloader(false);
    });
  }
  const onDeviceChange = (e) => {
    e.preventDefault();
    let data = e.target.value
    let splidata = data.split('$');
    setDeviceID(splidata[1]);
    handleTabsSelect(0, splidata[1]);
    //fetchDashboarVal(splidata[0], splidata[1]);
  };

  // const fetchDashboarVal = (batchID, deviceID) => {
  //   if(batchID === '' || deviceID === '' || selectedFarmer == '') {
  //     alert('Error in fetching data');
  //     return;
  //   }
  //   const currentDate = moment().format('YYYY-MM-DD');
  //   console.log(env.produrl+`/adminDashboard/${batchID}/${deviceID}/${currentDate}/${selectedFarmer}`)
  //   setShowloader(true);
  //   axios.get(env.produrl+`/adminDashboard/${batchID}/${deviceID}/${currentDate}/${selectedFarmer}`, {headers: headersobj}
  //   ).then(res=>{
  //           if(!res.data.errStatus) {
  //             // if(res.data.data.length === 0) {
  //             //   alert('No devices found this farm');
  //             // }
  //             setDashBoardVals(res.data.data);
  //             setShowloader(false);
  //           } else {
  //             setShowloader(false);
  //             alert(res.data.message)
  //           }
  //        }).catch((err) =>{
  //         alert(err.message)
  //             console.log(err);
  //             setShowloader(false);
  //        });
  // }
  const [xdata, setXdata] = useState([]);
  const [ydata, setYdata] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [paramTitle, setPramTitle] = useState('');
  const [latestValues, setLatestValues] = useState({});
  const [dayMinMax, setDayMinMax] = useState({});
  const getDayValues = (deviceID, date) => {
    axios.get(env.produrl + `/misc/historyReadings/${date}/${deviceID}`, { headers: headersobj }
    ).then(res => {
      if (!res.data.error) {
        if (res.data.data.length === 0) {
          setErrorMessage('No Data Found');
          // alert('No Data Found');
        }
        // alert(JSON.stringify(res.data.data.latest[0]))
        setLatestValues(res.data.data.latest[0]);
        setDayMinMax(res.data.data.dayMinMax[0]);
      } else {
        alert('Error in fetching data')
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err);
      setLatestValues('')
      setShowloader(false);
    });
  }
  const [key, setKey] = useState('0');

  const handleTabsSelect = (key, uuid) => {
    let param = '';
    setKey(key);
    if (key == 0) {
      param = 'soilMoisture'
      setPramTitle('Soil Moisture')
      
    } else if (key == 1) {
      param = 'soilTemperature'
      setPramTitle('Soil Temperature')
    } else if (key == 2) {
      param = 'soilNitrogen';
      setPramTitle('Soil Nitrogen')

    } else if (key == 3) {
      param = 'soilPhosphorous';
      setPramTitle('Soil Phosphorous')
    } else if (key == 4) {
      param = 'soilPotassium'
      setPramTitle('Soil Potassium')
    } else if (key == 5) {
      setPramTitle('Soil PH')
      param = 'soilPH'
    } else if (key == 6) {
      setPramTitle('Soil EC')
      param = 'soilEC'
    } else if (key == 7) {
      setPramTitle('CO2')
      param = 'co2'
    }

    if (param == '') {
      setErrorMessage('No Data Found');
    }
    let _deviceID = '';
    if (deviceID === '') {
      _deviceID = uuid
    } else {
      _deviceID = deviceID
    }
    const _currentDate = moment().format('YYYY-MM-DD');

    getDayValues(_currentDate, _deviceID);
    //console.log(env.produrl+`/misc/paramReadings/soilPH/866897052753706/2023-04-15`)
    axios.get(env.produrl + `/misc/paramReadings/${param}/${_deviceID}/${_currentDate}`, { headers: headersobj }
    ).then(res => {
      if (!res.data.error) {
        console.log('Hello', res.data.data.xdata)
        if (res.data.data.xdata.length === 0) {
          setErrorMessage('No Data Found');
           alert('No Data Found');
          return;
         
        }
        setShowTabs(true);
        console.log(res.data.data);
        setXdata(res.data.data.xdata);
        setYdata(res.data.data.ydata)
      } else {
        alert('Error in fetching data')
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err);
      setXdata([]);
      setYdata([]);
      setShowloader(false);
    });
  }
  useEffect(() => {
   
    if(userType=='CS') {
      fetchFarms();
    } else {
      fetchFarmers();
    }
    //handleTabsSelect(0);
  }, []);

  return (
    <div className="dashboard">
      <div className="row">

        {/* <div className="col-md-2 dashbioardLoderContainer">
          <h3>Dashboard</h3>
          <div className="dashboardLoader">
            { showLoader?
              <section {...containerProps} style = {{"margin-top": "30px"}}>
                  {indicatorEl}
              </section> :""
            }
          </div>
        </div> */}

        <div className="col-md-12 pull-right">
          <div className="row">
            {/* <div className="col-md-4 text-right">Filter</div> */}
            {
              userType=='Admin'?
            
            <div className="col-md-3">
              <div className="form-group">
                <label>Select Farm Owner</label>
                <select
                  className="form-control select2 input-sm"
                  onChange={onUserChange}
                  style={{ width: "100%" }}>
                  <option>-Select-</option>
                  {farmerList.map(item => (
                    <option
                      key={item.userID}
                      value={item.userID}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            :<></>}
            <div className="col-md-3">
              <label>Select Farm</label>
              <select
                onChange={onFarmChange}
                className="form-control select2"
                style={{ width: "100%" }}>
                <option>-Select-</option>
                {farmsList.map(item => (
                  <option
                    key={item.farmID}
                    value={item.farmID}
                  >
                    {item.farmName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Batch/Installed Devices</label>
              <select
                onChange={onDeviceChange}
                className="form-control select2"
                style={{ width: "100%" }}>
                <option>-Select-</option>
                {devicesList.map(item => (
                  <option
                    key={item.batchID}
                    value={item.batchID + '$' + item.uuid}
                  >
                    {item.batchName + ' - ' + item.deviceName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2 dashbioardLoderContainer">
              <label>&nbsp;</label>
              <div className="dashboardLoader" style={{ marginTop: 10 }}>
                {showLoader ?
                  <section {...containerProps} style={{ "margin-top": "30px" }}>
                    {indicatorEl}
                  </section> : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="chartArea row clearfix">
        <section className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h6><i class="fas fa-th mr-1"></i> 
              Temperature
              <ul className="dayReadings">
                <li>Current: <span>45 °C</span></li>
                <li>Min: <span>45 °C</span></li>
                <li>Max: <span>45 °C</span></li>
              </ul>
              </h6>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
                
                 
            </div>
          </div>
        </section>
        <section className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h6>
                <i class="fas fa-th mr-1"></i> Humidity
                <ul className="dayReadings">
                  <li>Current: <span>45 °C</span></li>
                  <li>Min: <span>45 °C</span></li>
                  <li>Max: <span>45 °C</span></li>
                </ul>
              </h6>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
                
                 
            </div>
           
          </div>
        </section>
        <section className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h6><i class="fas fa-th mr-1"></i> CO2
                <ul className="dayReadings">
                  <li>Current: <span>45 °C</span></li>
                  <li>Min: <span>45 °C</span></li>
                  <li>Max: <span>45 °C</span></li>
                </ul>
              </h6>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
                
                 
            </div>
          
          </div>
        </section>
        <section className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h6><i class="fas fa-th mr-1"></i> Ammonia
                <ul className="dayReadings">
                  <li>Current: <span>45 °C</span></li>
                  <li>Min: <span>45 °C</span></li>
                  <li>Max: <span>45 °C</span></li>
                </ul>
              </h6>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
                
        
            </div>
          
          </div>
        </section>
      </div> */}
      <div>
        {showTabs ? <Tabs activeKey={key}
          onSelect={(k) => handleTabsSelect(k, '')}
          className="mb-3 custom-tabs"
          unmountOnExit={true}

        >
          <Tab title="Soil Moisture" eventKey="0" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilMoisture} dayMin={dayMinMax.soilMoisture_min} dayMax={dayMinMax.soilMoisture_max}></TabChart>

          </Tab>
          <Tab title="Soil Temperature" eventKey="1" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilTemperature} dayMin={dayMinMax.soilTemperature_min} dayMax={dayMinMax.soilTemperature_max}
            ></TabChart>
          </Tab>
          <Tab title="Soil Nitrogen" eventKey="2" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata} title={paramTitle}
              lastUpdated={latestValues.createdDate}
              latest={latestValues.soilNitrogen} dayMin={dayMinMax.soilNitrogen_min} dayMax={dayMinMax.soilNitrogen_max}
            ></TabChart>
          </Tab>
          <Tab title="Soil Phosphorous" eventKey="3" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilPhosphorous}
              dayMin={dayMinMax.soilPhosphorous_min} dayMax={dayMinMax.soilPhosphorous_max}
            ></TabChart>
          </Tab>
          <Tab title="Soil Potassium" eventKey="4" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilPotassium}
              dayMin={dayMinMax.soilPotassium_min} dayMax={dayMinMax.soilPotassium_max}
            ></TabChart>
          </Tab>
          <Tab title="Soil PH" eventKey="5" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilPH}
              dayMin={dayMinMax.soilPH_min} dayMax={dayMinMax.soilPH_max}
            ></TabChart>
          </Tab>
          <Tab title="Soil EC" eventKey="6" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.soilEC}
              dayMin={dayMinMax.soilEC_min} dayMax={dayMinMax.soilEC_max}
            ></TabChart>
          </Tab>
          <Tab title="CO2" eventKey="7" >
            <TabChart key={Math.random()} xaxis={xdata} yaxis={ydata}
              title={paramTitle} lastUpdated={latestValues.createdDate}
              latest={latestValues.co2}
              dayMin={dayMinMax.co2_min} dayMax={dayMinMax.co2_max}
            ></TabChart>
          </Tab>
        </Tabs> :
          <div>Please select all parameters</div>
        }
      </div>

    </div>
  );
};
export default Dashboard;
