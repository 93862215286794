import React from "react";
import logo from "./logo.svg";
import "./App.css";

import TopHeader from "./components/layout/TopHeader";
import TopContent from "./components/layout/TopContent";
import TopFooter from "./components/layout/TopFooter";

import Dashboard from "./components/pages/Dashboard";
import BatchOperations from "./components/pages/BatchOperations";
import Users from "./components/pages/Listofusers";
import Login from "./components/pages/Login";

import AbnormalReadings from "./components/pages/AbnormalReadings";
import ThresholdSettings from "./components/pages/ThresholdSettings";
import DeviceMaster from "./components/pages/DeviceMaster";
import DeviceConnections from "./components/pages/DeviceConnections";
import Indus from "./components/pages/Indus";
import Farms from "./components/pages/Farms";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
        <Route exact path="/" component={Login}></Route>
        <Route exact path="/login" component={Login}></Route>
          <div class="wrapper">
            <TopHeader />
            <div className="content myappcontent">
              <div className="container-fluid">
                <Route exact path="/indus" component={Indus}></Route>
                <Route exact path="/dashboard" component={Dashboard}></Route>
                <Route exact path="/batchOperations" component={BatchOperations}></Route>
                <Route exact path="/users" component={Users}></Route>
                <Route exact path="/deviceConnections" component={DeviceConnections}></Route>
                <Route exact path="/abnormalReadings" component={AbnormalReadings}></Route>
                <Route exact path="/thresholdSettings" component={ThresholdSettings}></Route>
                <Route exact path="/deviceMaster" component={DeviceMaster}></Route>
                <Route exact path="/farms" component={Farms}></Route>
              </div>
            </div>
            <TopFooter />
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
