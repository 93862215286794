import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { headers } from '../utils/common';
import axios from "axios";
import { env } from './const';
import moment from "moment";

const AbnormalReadingsModal = ({ show, handleClose, rowData }) => {

    const [data, setData] = useState([]);
    const [headersobj] = useState(headers());
    const [searchTerm, setSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const columns = [
        {
            name: 'Sensor',
            selector: row => row.sensor_type,
            sortable: true,
        },
        {
            name: 'Value',
            selector: row => row.reading_value,
            sortable: true,
        },
        {
            name: 'Timestamp',
            selector: row => row.timestamp ? moment(row.timestamp).format('DD-MM-YYYY hh:mm:ss') : '',
            sortable: true,
        }
    ];
    const abnormalReadings = () => {

        axios.get(env.produrl + '/misc/abnormalReadings/' + rowData?.imeiNumber, { headers: headersobj })
            .then(res => {
                if (!res.data.status) {
                    alert(res.data.message);
                    return;
                }
                setData(res.data.data)
                setOriginalData(res.data.data)
            }).catch((err) => {
                alert(err.message);
                console.log(err);
            });
    };
    
    useEffect(() => {
        if (rowData?.imeiNumber) {
            abnormalReadings();
        }
        if (searchTerm !== '' && searchTerm.length > 2) {
            const filteredData = data.filter(item => {
                const sensor_type = item.sensor_type || "";
                const value = item.value || "";
                
                return sensor_type.toLowerCase().includes(sensor_type.toLowerCase()) ||
                value.toLowerCase().includes(value.toLowerCase()) 
            });
            console.log(filteredData);
            setData(filteredData);
        } else {
            setData(originalData);
        }
    }, [rowData?.imeiNumber]);
    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Abnormal Readings for IMEI Number: {rowData?.imeiNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div class="input-group">
                        <input type="search" className="form-control form-control-sm"
                            placeholder="Search By Sennsor Type/Value"
                            //value={searchTerm}
                            onKeyUp={(e) => setSearchTerm(e.target.value)}
                        />
                        <div class="input-group-append">
                            <div type="submit" className="btn btn-sm btn-default">
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </div>
                </form>
                <DataTable
                    columns={columns}
                    data={data} // Replace with API call data
                    pagination
                    dense
                    striped
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AbnormalReadingsModal;
