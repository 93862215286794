import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLoading, Bars } from '@agney/react-loading';
import { env } from './const';
import axios from "axios";
import { headers } from '../utils/common';
const ThresholdInput = ({ label, name, register, errors, defaultValue }) => (
    <div className="col-md-6">
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <input
                type="text"
                className="form-control"
                placeholder={label}
                name={name}
                defaultValue={defaultValue}
                {...register(name, { required: `Please enter ${label}` })}
            />
            {errors[name] && <span className="err-msg">{errors[name].message}</span>}
        </div>
    </div>
);

const ThresholdSettings = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [formData, setFormData] = useState(null);
    const [headersobj] = useState(headers());
    useEffect(() => {
        fetchData();
        // fetchData().then(data => {
        //     console.log("*****", data)
        //     setFormData(data);
        //     // for (const key in data) {
        //     //     setValue(key, data[key]); // Prepopulate form values
        //     // }
        //     setValue('soilMoistureMin', data['soilMoistureMin'])
        // });
    }, [setValue]);

    const fetchData = () => {

        axios.get(env.produrl + '/misc/getAbnormalThresholds/', { headers: headersobj })
            .then(res => {

                if (res.data.data.length > 0) {
                    const data  = res.data.data[0]
                    setFormData(data);
                    for (const key in data) {
                        setValue(key, data[key]); // Prepopulate form values
                    }
                } else {
                    return;
                }
            }).catch((err) => {
                console.log(err);
            });
    };

    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const onSubmit = (data) => {

        axios.post(env.produrl + `/admin/abnormalThresholds`, data)
            .then(res => {
                if (res.status === 200) {
                    setShowResults(true);
                    setResultMessage({
                        error: false,
                        message: res.data.message
                    });
                } else {
                    setResultMessage({
                        error: true,
                        message: 'Unexpected status returned: ' + res.status
                    });
                }
            })
            .catch((err) => {
                alert(err.message);
                console.log(err);
                setResultMessage({
                    error: true,
                    message: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'An error occurred'
                });
            });
    }

    return (
        <div className="Thresholds">
            <div className="row">
                <div className="col-md-12 pull-right">
                    <ul className="headerTitle">
                        <li><h3>Threshold Settings</h3></li>
                        <div className="card">
                            <div className="card-body">
                                {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {[
                                            { title: 'Soil Moisture', fields: ['soilMoistureMin', 'soilMoistureMax'] },
                                            { title: 'Soil Temperature', fields: ['soilTempMin', 'soilTempMax'] },
                                            { title: 'Soil Nitrogen', fields: ['soilNitrogenMin', 'soilNitrogenMax'] },
                                            { title: 'Soil Phosphorous', fields: ['soilPhosporousMin', 'soilPhosporousMax'] },
                                            { title: 'Soil Potassium', fields: ['soilPotassiumMin', 'soilPotassiumMax'] },
                                            { title: 'Soil PH', fields: ['soilPHMin', 'soilPHMax'] },
                                            { title: 'Soil EC', fields: ['soilECMin', 'soilECMax'] },
                                            { title: 'Air Temperature', fields: ['airTemperatureMin', 'airTemperatureMax'] },
                                            { title: 'Wind Speed', fields: ['windSpeedMin', 'windSpeedMax'] },
                                            { title: 'Battery Voltage', fields: ['batteryVoltageMin', 'batteryVoltageMax'] },
                                            { title: 'Humidity', fields: ['humidityMin', 'humidityMax'] },
                                            { title: 'CO2', fields: ['co2Min', 'co2Max'] }
                                        ].map((section, index) => (
                                            <div className="col-md-3" key={index}>
                                                <div className="row">
                                                    <div className="col-md-12"><h5 style={{ color: '#187800' }}>{section.title}</h5></div>
                                                </div>
                                                <div className="row">
                                                    {section.fields.map((field, idx) => (
                                                        <ThresholdInput
                                                            key={idx}
                                                            label={field.includes('Min') ? 'Min' : 'Max'}
                                                            name={field}
                                                            register={register}
                                                            errors={errors}
                                                            defaultValue={formData ? formData[field] : ''}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                        </div>
                                        <div className="col-2">
                                            <button type="reset" className="btn btn-secondary btn-block">Reset</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
};
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default ThresholdSettings;
