import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { IconName } from "react-icons/ai";
import { AiOutlineReload } from "react-icons/ai";
import { Modal, Button } from 'react-bootstrap';
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
const DeviceConnections = () => {

    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);
    const [connectionData, setConnectionData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true,
            width: '70px',
            cell: (row, index) => index + 1 // Add 1 because index starts from 0
        },
        {
            name: "IMEI Number",
            selector: "imeiNumber",
            width: '200px',
            sortable: true,
            cell: row => <div style={{ cursor: 'pointer', color: 'blue' }} onClick={clickHandler} id={row.deviceID + ',' + row.imeiNumber} >{row.imeiNumber}</div>,

        },
        {
            name: "Device Name",
            selector: "deviceName",
            width: '250px',
            sortable: true,
            cell: row => <div>{row.deviceName}</div>,
        },
        {
            name: "Signal Strength",
            selector: "signalStrength",

            sortable: true,
            cell: row => <div className={row.colorCode}><b>{row.signalStrength}</b></div>,
        },
        {
            name: "Status",
            selector: "connectionStatus",
            width: '180px',
            sortable: true,
            cell: row => <div className={row.colorCode}>{row.connectionStatus}</div>,
        },
        {
            name: "Last updated at",
            selector: "lastUpdatedDate",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.lastUpdatedDate}</div>,
        },
        {
            name: "Farmer",
            selector: "assignedTo",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.assignedTo}</div>,
        },
        {
            name: "Installed Location",
            selector: "currentLocation",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.currentLocation}</div>,
        },
    ];
    const columns2 = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true,
            width: '70px',
            cell: (row, index) => index + 1 // Add 1 because index starts from 0
        },

        {
            name: "Installed Location",
            selector: "installedLocation",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.installedLocation}</div>,
        },
        {
            name: "Installed Date",
            selector: "installedDate",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.installedDate != null ? moment(row.installedDate).format('DD-MMM-YYYY HH:mm') : ''}</div>,
        },
        {
            name: "Batch",
            selector: "batchName",
            width: '150px',
            sortable: true,
            cell: row => <div>{row.batchName}</div>,
        },
        {
            name: "Status",
            selector: "status",
            width: '100px',
            sortable: true,
            cell: row => <div>{row.status}</div>,
        },
    ];
    const [originalData, setOriginalData] = useState([]); // State to keep original data
    const connections = () => {
        setShowloader(true);
        axios.get(env.produrl + '/admin/deviceConnections', { headers: headersobj })
        .then(res => {
            if (!res.data.status) {
                alert(res.data.message);
                return;
            }
            setProgress(false);
            setConnectionData(res.data.list);
            setOriginalData(res.data.list); // Store the fetched data in originalData
            setShowloader(false);
        }).catch((err) => {
            alert(err.message);
            console.log(err);
            setConnectionData([]);
            setOriginalData([]); // Also reset original data on error
            setShowloader(false);
        });
    };
    const [moreDate, setMoreData] = useState([]);
    const [imeiNumber, setImeiNumber] = useState('');
    const moreDetails = (deviceID) => {
        setShowloader(true)

        //alert(env.produrl + `/admin/moreDetails/${deviceID}`)
        axios.get(env.produrl + `/admin/moreDetails/${deviceID}`, { headers: headersobj }
        ).then(res => {
            if (!res.data.status) {
                alert(res.data.message);
                return;
            }
            setMoreData(res.data.list);
            setShowloader(false);
        }).catch((err) => {
            alert(err.message)
            console.log(err);
            setMoreData([]);
            setShowloader(false);
        });
    }
    const clickHandler = async (state) => {
        setShowPopup(true);
        let _state = state.target.id;
        let splitState = _state.split(',');
        setImeiNumber(splitState[1]);
        moreDetails(splitState[0]);
    };
    const [progress, setProgress] = useState(true);
    const handleChangeSelectedRows = (state) => {
        //alert(JSON.stringify(state.selectedRows))
        setSelectedRows(state.selectedRows)
    }
    const closePopup = () => {
        setShowPopup(false);
    }
    useEffect(() => {
        connections();
    }, []);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        if (searchTerm !== '' && searchTerm.length > 2) {
            const filteredData = connectionData.filter(item => {
                const deviceName = item.deviceName || "";
                const assignedTo = item.assignedTo || "";
                const currentLocation = item.startDateTime || "";
                const imei = item.imeiNumber || "";
                const connectionStatus = item.connectionStatus || "";
               
                return deviceName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    assignedTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    imei.includes(searchTerm) ||
                    currentLocation.includes(searchTerm) || connectionStatus.toLowerCase().includes(searchTerm.toLowerCase())
            });
            setConnectionData(filteredData);
        } else {
            setConnectionData(originalData);
        }
    }, [searchTerm, connectionData]);
    return (
        <div className="Connnections">
            <div className="row">
                <div className="col-md-8 pull-right">
                    <ul className="headerTitle">
                        <li><h3>Device Connections</h3></li>
                        <li>
                            {showLoader ?
                                <section {...containerProps} style={{ "margin-top": "0px" }}>
                                    {indicatorEl}
                                </section> : ""
                            }
                        </li>
                    </ul>
                </div>
                <div className="col-md-4" style={{ textAlign: "right" }}>
                    <span className="refresh" onClick={() => { connections() }}><AiOutlineReload style={{ marginTop: -5 }} /> Refresh</span>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8">
                            <form>
                                <div class="input-group">
                                    <input type="search" className="form-control form-control-sm"
                                        placeholder="Search By IMEI/Farmer/Device Name/Intalled Date/Connection Status"
                                        //value={searchTerm}
                                        onKeyUp={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <div class="input-group-append">
                                        <div type="submit" className="btn btn-sm btn-default">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <DataTable
                        progressPending={progress}
                        columns={columns}
                        data={connectionData}
                        defaultSortAsc={false}
                        pagination
                        dense='false'
                        compact
                        highlightOnHover='true'
                        striped
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    //onRowSelected={handleChangeSelectedRows}
                    />

                    {/* Card Body Ends */}
                </div>

            </div>
            <Modal show={showPopup} size='lg'>
                <Modal.Header>
                    <Modal.Title>Installation History - <strong>{imeiNumber}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <DataTable
                                //progressPending={progress}
                                columns={columns2}
                                data={moreDate}
                                defaultSortAsc={false}
                                pagination
                                dense='false'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closePopup}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default DeviceConnections;
