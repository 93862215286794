import React from "react";

const TopFooter = (props) => {
  return (
    <div>
      <footer class="main-footer">
          Copyright &copy; 2024-2025 <a href="https://neoaura.com/#/Home" target="_blank">NEOAURA</a>. All rights reserved.
      </footer>
    </div>
  );
};
export default TopFooter;
