import React  from 'react';
import ReactEcharts  from 'echarts-for-react';
import EchartLine from './echarts';
import moment from 'moment';
import { NavLink } from "react-router-dom";
const TabChart = (props)=>   {
   

    return(
    <>
    <h4>{props.title} - Houlry Average Readings</h4>
        <div className="row col-bg">
            
            <div className="col-md-8">
                <EchartLine xaxis={props.xaxis} yaxis={props.yaxis} maxVal='50' color='#4f46e5' interval= {2} 
                    max={24} min= {36}></EchartLine>
            </div>
            <div className="col-md-4">
            <ul className="readings_summary">
                <li className="current">{props.latest}</li>
                <li><b>Day Min: </b>{props.dayMin}</li>
                <li><b>Day Max: </b>{props.dayMax}</li>
                <li><b>Last Updated at: </b>{moment(props.createdDate).utcOffset(330).format('DD-MMM-YYYY hh:mm A')}</li>
            </ul>
            <NavLink exact to="/batchOperations" className="nav-link">
            View day-wise readings
                </NavLink>
            
            </div>
        </div>
    </>
   
    );
}
export default TabChart;