import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars } from '@agney/react-loading';
import { headers, getUserType } from '../utils/common';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";

const BatchOperations = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [userType] = useState(getUserType());
    const [showLoader, setShowLoader] = useState(false);
    const [farmerList, setFarmerList] = useState([]);
    const [farmsList, setFarmsList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);
    const [selectedFarmer, setSelectedFarmer] = useState('');
    const [deviceID, setDeviceID] = useState('');
    const [showReadings, setShowReadings] = useState(false);
    const [value, onChange] = useState(new Date());
    const [deviceReadings, setDeviceReadings] = useState([]);
    const [progress, setProgress] = useState(true);
    const [selectorsFilled, setSelectorsFilled] = useState(false);

    const onUserChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (value === '') {
            alert('Please select Farm Owner');
            return;
        }
        setFarmsList([]);
        setDevicesList([]);
        setShowReadings(false);
        setSelectedFarmer(value);
        fetchFarms(value);
        setDeviceReadings([]);
    };

    const onFarmChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (value === '') {
            alert('Please select Farm');
            return;
        }
       // setShowTabs(false);
        fetchDevices(value);
        setDeviceReadings([]);
    };

    const onDeviceChange = (e) => {
        e.preventDefault();
        setDeviceReadings([]);
        const data = e.target.value;
        const [batchID, uuid] = data.split('$');
        setDeviceID(uuid);
    };

    useEffect(() => {
      
        if (selectedFarmer && deviceID) {
            setSelectorsFilled(true);
        } else {
            setSelectorsFilled(false);
        }
    }, [selectedFarmer, deviceID]);

    useEffect(() => {
        if (deviceID) {
            const currentDate = moment().format('YYYY-MM-DD');
            handleDateChange(currentDate, deviceID);
        }
    }, [deviceID]);

    const handleDateChange = (date, uuid = deviceID) => {
        if (!uuid) {
            alert('Please select a device before selecting a date.');
            return;
        }
        const selectedDate = moment(date).format('YYYY-MM-DD');
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/deviceReadings/${uuid}/${selectedDate}`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setDeviceReadings(res.data.data);
                    setShowReadings(res.data.data.length > 0);
                } else {
                    alert('Error in fetching data');
                }
                setProgress(false);
            }).catch((err) => {
                alert(err.message);
                setDeviceReadings([]);
                setShowReadings(false);
                setShowLoader(false);
                setProgress(false);
            });
    };

    const fetchFarmers = () => {
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/activeFarmersList/`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setFarmerList(res.data.data);
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const fetchFarms = (userID) => {
        setShowLoader(true);
        let apiLink = `/misc/listoffarmsAdmin/${userID}`;
        if(userType=='CS') {
          apiLink = `/misc/listoffarmsAdmin/7`;
        }
        axios.get(`${env.produrl}${apiLink}`, { headers: headersobj })
            .then(res => {
                setDevicesList([])
                setShowLoader(false);
                if (!res.data.error) {
                    setFarmsList(res.data.data);
                    if (res.data.data.length === 0) {
                        alert('No farms found for the selected farmer');

                    }
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const fetchDevices = (farmID) => {
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/listofDevicesByFarm/${farmID}`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    if (res.data.data.length === 0) {
                        alert('No devices found on this farm');
                        return;
                    }
                    setDevicesList(res.data.data);
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const columns = [
        { name: "Date Time", selector: "createdDate", sortable: true, width: '120px', cell: row => <div>{row.createdTime}</div> },
        { name: "Soil Temperature", selector: "soilTemperature", sortable: true, width: '150px', cell: row => <div>{row.soilTemperature}</div> },
        { name: "Soil Moisture", selector: "soilMoisture", width: '150px', sortable: true, cell: row => <div>{row.soilMoisture}</div> },
        { name: "Soil Nitrogen", selector: "soilNitrogen", width: '150px', sortable: true, cell: row => <div>{row.soilNitrogen}</div> },
        { name: "Soil Phosphorous", selector: "soilPhosphorous", sortable: true, width: '150px', cell: row => <div>{row.soilPhosphorous}</div> },
        { name: "Soil Potassium", selector: "soilPotassium", width: '150px', sortable: true, cell: row => <div>{row.soilPotassium}</div> },
        { name: "Soil PH", selector: "soilPH", width: '150px', sortable: true, cell: row => <div>{row.soilPH}</div> },
        { name: "Soil EC", selector: "soilEC", width: '150px', sortable: true, cell: row => <div>{row.soilEC}</div> },
        { name: "Air Temperature", selector: "airTemperature", width: '150px', sortable: true, cell: row => <div>{row.airTemperature}</div> },
        { name: "CO2", selector: "co2", sortable: true, cell: row => <div>{row.co2}</div> }
    ];

    useEffect(() => {
        if(userType =='CS') {
            fetchFarms();
            setSelectedFarmer('undefined')
        } else {
            fetchFarmers();
        }
        
    }, []);

    return (
        <div className="BatchOperations">
            <div className="row">
                <div className="col-md-12">
                    <h3>Readings</h3>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {userType=='Admin'?
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Select Farm Owner</label>
                                        <select className="form-control select2 input-sm" onChange={onUserChange} style={{ width: "100%" }}>
                                            <option>-Select-</option>
                                            {farmerList.map(item => (
                                                <option key={item.userID} value={item.userID}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                :<></>}
                                <div className="col-md-3">
                                    <label>Select Farm</label>
                                    <select onChange={onFarmChange} className="form-control select2" style={{ width: "100%" }}>
                                        <option>-Select-</option>
                                        {farmsList.map(item => (
                                            <option key={item.farmID} value={item.farmID}>{item.farmName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>Batch/Installed Devices</label>
                                    <select onChange={onDeviceChange} className="form-control select2" style={{ width: "100%" }}>
                                        <option>-Select-</option>
                                        {devicesList.map(item => (
                                            <option key={item.batchID} value={item.batchID + '$' + item.uuid}>
                                                {item.batchName + ' - ' + item.deviceName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <div className="dashboardLoader" style={{ marginTop: 10 }}>
                                        {showLoader ? (
                                            <section {...containerProps} style={{ marginTop: "30px" }}>
                                                {indicatorEl}
                                            </section>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="DeviceReadings" style={{marginTop: 20}}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <h5>Calendar</h5>
                                        <Calendar onChange={onChange} value={value} onClickDay={(date) => handleDateChange(date)} />
                                        {deviceReadings.length > 0 && <CSVLink data={deviceReadings}>Export Data to CSV</CSVLink>}
                                    </div>
                                    <div className="col-md-8">
                                        <h5>Device Readings</h5>
                                        {selectorsFilled ? (
                                            showReadings ? (
                                                <DataTable
                                                    progressPending={progress}
                                                    columns={columns}
                                                    data={deviceReadings}
                                                    defaultSortField="createdDate"
                                                    defaultSortAsc={false}
                                                    pagination
                                                    dense={false}
                                                    highlightOnHover
                                                    striped
                                                    paginationPerPage={15}
                                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                                />
                                            ) : (
                                                <p>No device readings available for the selected date.</p>
                                            )
                                        ) : (
                                             userType=='Admin'?
                                             <p>Please select a farm owner, farm and device to view readings.</p>
                                             :<p>Please farm and device to view readings.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchOperations;
