import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers, getUserType } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiOutlineWarning } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineBulb } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { Tab, Tabs } from 'react-bootstrap';
import Batchops from "./Batchops";

const Indus = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);
    const [userType] = useState(getUserType());
    const [connectionData, setConnectionData] = useState([]);
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true,
            width: '70px',
            cell: (row, index) => index + 1 // Add 1 because index starts from 0
        },
        {
            name: "IMEI Number",
            selector: "imeiNumber",
            width: '200px',
            sortable: true,
            cell: row => <div style={{ cursor: 'pointer', color: 'blue' }} >{row.imeiNumber}</div>,

        },
        {
            name: "Device Name",
            selector: "deviceName",
            width: '250px',
            sortable: true,
            cell: row => <div>{row.deviceName}</div>,
        },
        {
            name: "Signal Strength",
            selector: "signalStrength",

            sortable: true,
            cell: row => <div className={row.colorCode}><b>{row.signalStrength}</b></div>,
        },
        {
            name: "Status",
            selector: "connectionStatus",
            width: '180px',
            sortable: true,
            cell: row => <div className={row.colorCode}>{row.connectionStatus}</div>,
        },
        {
            name: "Last updated at",
            selector: "lastUpdatedDate",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.lastUpdatedDate}</div>,
        },
        // {
        //     name: "Farmer",
        //     selector: "assignedTo",
        //     width: '200px',
        //     sortable: true,
        //     cell: row => <div>{row.assignedTo}</div>,
        // },
        {
            name: "Installed Location",
            selector: "currentLocation",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.currentLocation}</div>,
        },
    ];
    const [originalData, setOriginalData] = useState([]); // State to keep original data
    const [key, setKey] = useState('ACTIVE');
    const counts = () => {

        axios.get(env.produrl + '/batchops/statusCounts/', { headers: headersobj })
            .then(res => {
                if (res.data) {
                    // alert(JSON.stringify(res.data.data))
                    setStatusCounts(res.data.data)
                }
            }).catch((err) => {
                console.log(err);

            });
    };
    const [statusCounts, setStatusCounts] = useState({
        commissioned: 0,
        decommissioned: 0,
        hibernated: 0,
        abnormalitiesToday: 0
    })
    useEffect(() => {
        counts();
    }, []);
    const [searchTerm, setSearchTerm] = useState('');
    const [progress, setProgress] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleChangeSelectedRows = (state) => {
        //alert(JSON.stringify(state.selectedRows))
        setSelectedRows(state.selectedRows)
    }
    useEffect(() => {
        if (searchTerm !== '' && searchTerm.length > 2) {
            const filteredData = connectionData.filter(item => {
                const deviceName = item.deviceName || "";
                const assignedTo = item.assignedTo || "";
                const currentLocation = item.startDateTime || "";
                const imei = item.imeiNumber || "";
                const connectionStatus = item.connectionStatus || "";
                return deviceName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    assignedTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    imei.includes(searchTerm) ||
                    currentLocation.includes(searchTerm) || connectionStatus.toLowerCase().includes(searchTerm.toLowerCase())
            });
            setConnectionData(filteredData);
        } else {
            setConnectionData(originalData);
        }
    }, [searchTerm, connectionData]);
    const handleSelect = (k) => {
        setKey(k);
    }


    return (
        <div className="dashboard">
            <div className="row">
                <div className="col-md-12 pull-right">
                    <ul className="headerTitle">
                        <li><h3>Dashboard</h3></li>

                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    {
                        userType == 'Admin' ?

                            <div className="totalDevices">
                                <strong># of abnormalities detected today:</strong>
                                <span>
                                    <span style={{ color: statusCounts.abnormalitiesToday > 0 ? 'red' : 'green' }}>
                                        {statusCounts.abnormalitiesToday}
                                    </span>
                                    <span>/{statusCounts.commissioned + statusCounts.hibernated + statusCounts.decommissioned}</span>
                                </span>

                            </div>
                            : <></>}
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 custom-tabs"
                        unmountOnExit={true}
                    >

                        <Tab eventKey="ACTIVE" title={`Commissioned (${statusCounts.commissioned})`}>
                            <Batchops action='ACTIVE' onUpdate={() => counts()} />
                        </Tab>
                        <Tab eventKey="HIBERNATION" title={`Hibernated (${statusCounts.hibernated})`}>
                            <Batchops action='HIBERNATION' onUpdate={() => counts()} />
                        </Tab>
                        <Tab eventKey="CLOSED" title={`De-Commissioned (${statusCounts.decommissioned})`}>
                            <Batchops action='CLOSED' onUpdate={() => counts()} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
export default Indus;
